import { render, staticRenderFns } from "./ReplicatedSiteNotAvailable.vue?vue&type=template&id=b424da22&scoped=true&"
import script from "./ReplicatedSiteNotAvailable.vue?vue&type=script&lang=js&"
export * from "./ReplicatedSiteNotAvailable.vue?vue&type=script&lang=js&"
import style0 from "./ReplicatedSiteNotAvailable.vue?vue&type=style&index=0&id=b424da22&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b424da22",
  null
  
)

export default component.exports