<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		:class="{ 'px-5': !['xs', 'sm'].includes(windowWidth) }"
		class="card border-0 mb-0 rounded-0 w-100 bg-top-gradient">
		<div
			class="row"
			:style="windowWidth === 'xs' ? 'min-height: 85vh' : 'min-height: 100vh'">
			<div class="col my-auto">
				<div class="text-center">
					<div
						:class="{ 'mt-5 pt-5': ['xs', 'sm'].includes(windowWidth) }"
						class="row">
						<div
							:class="{ 'mt-5': ['xs', 'sm'].includes(windowWidth) }"
							class="col mb-5">
							<img
								:style="{
									'max-height': ['xs', 'sm'].includes(windowWidth) ? '120px' : '180px',
									'max-width': ['xs', 'sm'].includes(windowWidth) ? '120px' : '180px',
								}"
								:src="require(`@/assets/images/common/page_not_found.png`)">
						</div>
					</div>
					<div class="row">
						<div class="col">
							<h2>{{ translate('replicated_site_not_available_title') }}</h2>
							<br>
							<h4 v-html="translate('replicated_site_not_available_description', { link: openLiveChat })" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { URIS } from '@/settings/LiveChat';
import { Store } from '@/translations';

export default {
	name: 'ReplicatedSiteNotAvailable',
	messages: [Store],
	mixins: [WindowSizes],
	data() {
		return {
			openLiveChat: URIS.MAXIMIZE_CHAT,
		};
	},
};
</script>
<style scoped>
.bg-top-gradient {
	background: linear-gradient(#999, #999 20px, transparent 140px, transparent);
}
</style>
